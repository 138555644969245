<template>
    <el-container>
        <el-row style="width: 100%">
            <el-row>
                <el-col :span="24">
                <div class="cardTitle">检查表题库</div>
                <el-button type="primary" size="mini" @click="addClass">新建分类</el-button>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="5">
                    <el-card shadow="never" style="height: 100%;width:100%">
                        <el-tree :data="classList" :props="{children: 'children', label: 'label'}" @node-click="nodeClick">
                            <span class="custom-tree-node" slot-scope="{node,data}" style="width: 100%">
                                <span :class="node.label==secondClassName?'itemColor': ''">{{ cutString(node.label) }}</span>
                                <el-dropdown class="moreBtn">
                                    <span class="el-dropdown-link">
                                        <i class="el-icon-more"></i>
                                    </span>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item>
                                            <div @click="editName(node,data)">编辑</div>
                                        </el-dropdown-item>
                                        <el-dropdown-item v-if="node.level == 1">
                                            <div @click="addClass(node,data)">新增</div>
                                        </el-dropdown-item>
                                        <el-dropdown-item>
                                            <div @click="deleteClass(data)">删除</div>
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </span>
                        </el-tree>
                    </el-card>
                </el-col>
                <el-col :span="18" :offset="1">
                    <el-card shadow="never">
                        <el-row style="margin-top: 5px">
                            <el-col :span="24">
                                <div class="cardTitle">{{secondClassName}}</div>
                                <el-button type="success" class="cardTitleBtn" size="small" v-if="secondClassName" @click="addQuestion">新建题目</el-button>
                            </el-col>
                            <el-col :span="24">
                                <el-table :data="questionList.records">
                                    <el-table-column label="题目" prop="title"></el-table-column>
                                    <el-table-column label="得分标准">
                                        <template slot-scope="scope">
                                            <p v-html="scope.row.standardDesc"></p>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="得分范围">
                                        <template slot-scope="scope">
                                            {{scope.row.scoreMin}} ~ {{scope.row.scoreMax}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="操作">
                                        <template slot-scope="scope">
                                            <el-button type="primary" size="small" @click="editQuestion(scope.row)">编辑</el-button>
                                            <el-button type="danger" size="small" @click="deleteQuestion(scope.row)">删除</el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </el-col>
                            <el-col :span="24">
                                <div class="block" style="margin-top: 20px" v-if="secondClassName" >  
                                    <el-pagination
                                        :current-page="questionList.nowPageNum"
                                        @size-change="handleSizeChange"
                                        @current-change="handleCurrentChange"
                                        :page-sizes="[ 20, 40, 80]"
                                        :page-size="pageSize"
                                        layout="total, sizes, prev, pager, next, jumper"
                                        :total="questionList.totalNum"
                                    >
                                    </el-pagination>
                                </div>
                            </el-col>
                        </el-row>
                    </el-card>
                </el-col>
            </el-row>
        </el-row>

        <el-dialog title="类型名称" width="20%" :visible.sync="classVisible">
            <el-form :model="classForm">
                <el-form-item>
                    <el-input v-model="className" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="classVisible = false">取 消</el-button>
                <el-button type="primary" @click="doAddClass">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog title="添加题目" width="50%" :visible.sync="questionVisible">
            <el-form :model="questionForm" label-width="80px">
                <el-row>
                    <el-form-item label="题目">
                        <el-input v-model="questionForm.title"></el-input>
                    </el-form-item>
                    <el-form-item label="标准描述">
                        <quill-editor v-model="questionForm.standardDesc" :options="editorOption">
                        </quill-editor>
                    </el-form-item>
                    <el-col :span="12">
                        <el-form-item label="最小得分">
                            <el-input-number style="width:100%" v-model="questionForm.scoreMin"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="最大得分">
                            <el-input-number style="width:100%" v-model="questionForm.scoreMax"></el-input-number>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="questionVisible = false">取 消</el-button>
                <el-button type="primary" @click="doAddQuestion">确 定</el-button>
            </div>
        </el-dialog>
    </el-container>
</template>

<script>
export default {
    data() {
        return {
            // 分类列表
            classList: [],
            classVisible: false,
            questionVisible: false,
            classForm: {
                guid: '',
                name: '',
                parentGuid: ''
            },
            className: '',
            secondClassName: '',
            pageSize: 20,
            questionList: [],
            classGuid: '',
            questionForm: {
                categoryGuid: '',
                guid: '',
                scoreMax: '',
                scoreMin: '',
                standardDesc: '',
                title: ''
            },
            editorOption: {
                placeholder: '请输入正文......',
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'],
                        [
                            { 'list': 'ordered'},
                            { 'list': 'bullet'}
                        ],
                        [
                            { 'color': [] }
                        ],
                        [
                            { 'align': [] }
                        ]
                    ]
                }
            }
        }
    },
    mounted() {
      let self = this;
      self.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      self.getClassTreeStructure();
    },
    methods: {
        // 获取分类树状结构
        getClassTreeStructure() {
            let self = this;
            self.$axios.get('/indexbase-category/tree',{
                headers: { token: self.userInfo.token }
            })
            .then(function(res) {
                self.classList = res.data.data
            })
            .catch(function(err) {
                self.$message({
                    message: "分类列表获取失败：" + err.response.data.message,
                    type: "warning"
                });
            })
        },
        // 新建分类
        addClass(node,data) {
            let self = this;
            if(node.level == 1) {
                self.classForm.parentGuid = data.value
                self.classForm.guid = ''
            }
            self.className = ''
            self.classVisible = true
        },
        // 确认新建分类
        doAddClass() {
            let self = this;
            self.classForm.name = self.className
            self.$axios.post('/indexbase-category/saveOrUpdate',self.classForm,{
                headers: { token: self.userInfo.token }
            })
            .then(function() {
                self.$message({
                    message: "添加成功",
                    type: "success",
                });
                self.getClassTreeStructure();
                self.classVisible = false
            })
            .catch(function(error) {
                self.$message({
                    message: "添加失败" + error.response.data.message,
                    type: "warning",
                });
            })
        },
        // 编辑按钮
        editName(node,data) {
            let self = this;
            if(node.level == 2) {
                self.classForm.parentGuid = node.parent.data.value
            } else if(node.level == 1) {
                self.classForm.parentGuid = ''
            }
            self.classForm.guid = data.value
            self.className = data.label
            self.classVisible = true
        },
        // 删除按钮
        deleteClass(data) {
            let self = this;
            self.$confirm("确定删除" + data.label + "吗？")
            .then(() => {
                self.$axios
                .delete("/indexbase-category/delete", {
                    data: { guid: data.value },
                    headers: { token: self.userInfo.token },
                })
                .then(function () {
                    self.$message({
                    message: "删除成功",
                    type: "success",
                    });
                    self.getClassTreeStructure();
                })
                .catch(function (error) {
                    self.$message({
                    message: "删除失败" + error.response.data.message,
                    type: "warning",
                    });
                });
            })
            .catch(() => {});
        },
        // 展示题目
        nodeClick(data,node) {
            let self = this
            if(node.level == 2) {
                self.secondClassName = data.label
                self.classGuid = data.value
                self.getQuestion(self.classGuid)
            }
        },
        // 获取题目
        getQuestion(guid,pageNum) {
            let self = this
            self.$axios.get('/indexbase-question/page?pageNum=' + (pageNum || 1) + '&pageSize='+self.pageSize+'&categoryGuid='+guid,{
                headers: { token: self.userInfo.token }
            })
            .then(function(res) {
                self.questionList = res.data.data
                console.log(guid);
                console.log(self.questionList);
            })
            .catch(function (error) {
                self.$message({
                message: "题目列表获取失败：" + error.response.data.message,
                type: "warning",
                });
            });
        },
        // 点击添加题目
        addQuestion() {
            let self = this
            self.questionForm = {
                categoryGuid: '',
                guid: '',
                scoreMax: '',
                scoreMin: '',
                standardDesc: '',
                title: ''
            }
            self.questionVisible = true
        },
        // 确认添加题目
        doAddQuestion() {
            let self = this
            self.questionForm.categoryGuid = self.classGuid
            console.log(self.questionForm);
            self.$axios.post('/indexbase-question/saveOrUpdate',self.questionForm,{
                headers: { token: self.userInfo.token },
            })
            .then(function() {
                self.$message({
                    message: "添加成功",
                    type: "success",
                });
                self.getQuestion(self.classGuid);
                self.questionVisible = false
            })
            .catch(function(error) {
                self.$message({
                    message: "添加失败" + error.response.data.message,
                    type: "warning",
                });
            })
        },
        // 编辑题目
        editQuestion(row) {
            console.log(row);
            let self = this
            self.questionForm = {
                categoryGuid: '',
                guid: row.guid,
                scoreMax: row.scoreMax,
                scoreMin: row.scoreMin,
                standardDesc: row.standardDesc,
                title: row.title
            }
            self.questionVisible = true
        },
        // 删除题目
        deleteQuestion(row) {
            let self = this;
            self.$confirm("确定删除" + row.title + "吗？")
            .then(() => {
                self.$axios
                .delete("/indexbase-question/delete", {
                    data: { guid: row.guid },
                    headers: { token: self.userInfo.token },
                })
                .then(function () {
                    self.$message({
                    message: "删除成功",
                    type: "success",
                    });
                    self.getQuestion(self.classGuid);
                })
                .catch(function (error) {
                    self.$message({
                    message: "删除失败" + error.response.data.message,
                    type: "warning",
                    });
                });
            })
            .catch(() => {});
        },
        // 改变显示个数
        handleSizeChange(val) {
            this.pageSize = val * 1;
            this.getQuestion(this.classGuid)
        },
        // 改变页码
        handleCurrentChange(val) {
            this.getQuestion(this.classGuid,val)
        },
        cutString(str){
           if(str.length>8){
               return str.slice(0,8)+"..."
           }else{
               return str
           }
        }
    }
}
</script>

<style>
    .moreBtn{
        float: right;
        color: #409EFF;
        cursor: pointer;
    }
    .itemColor {
        color: #409EFF;
    }
    .ql-editor{
        height:150px;
    }
    .el-tree-node__content{
        height: 40px;
    }
</style>